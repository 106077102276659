import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt} from "react-icons/fa";

function Contact() {
  return (
    <div className="container contact">
      <h2 className="main-title text-center">CONTACT</h2>
      <div className="cardstyle">
      <h4 className="contactwords">Feel free to contact me at:</h4>
      <div className="contactcontents">
        <div className="Email"><FaEnvelope /> <a className="maillink" href="mailto:cuttingtaxes@gmail.com"> cuttingtaxes@gmail.com</a></div>
        <div className="Phone"><FaPhoneAlt/> &nbsp;727-599-7450</div>
       </div>
      </div>
    </div>
  );
}
export default Contact;