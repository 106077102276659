import { useState } from 'react';

// Createa reusable Read More/Less component
const ExpandableText = ({ children, descriptionLength }) => {
  const fullText = children;

  // Set the initial state of the text to be collapsed
  const [isExpanded, setIsExpanded] = useState(false);

  // This function is called when the read more/less button is clicked
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <p className='text'>
      {isExpanded ? fullText : `${fullText.slice(0, descriptionLength)}...`}
      <span onClick={toggleText} className='toggle-button'>
        {isExpanded ? 'Show less' : 'Show more'}
      </span>
    </p>
  );
};
function News() {
  return (
    <>
      <div
        style={{
          marginTop: "0",
          width: "100%",
          height: "0",
          paddingBottom: "5vw",
        }}
        className="news-scroll"
      ></div>
      <div className="container news">
        <h2 className="main-title text-center">Latest News</h2>
        <div className="card-cover">
          <div className="col-md-12">
            <div className="row">


            <div className="col-md-4 mb-2">
                <div className="card">
                  <br />
                  <div className="text-center">
                    <div className="Date-label">
                      <p>Dec 15, 2023</p>
                    </div>
                  </div>
                  <div className="text-center">
                    <h3 className="card-title">
                    Get Ready to File Your Taxes
                    </h3>
                  </div>
                  <div className="p-3">
                    <p>Here is a video tax tip from the IRS:</p>
                  
                    <a href="www.youtube.com/@irsvideos"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      IRS Videos</a>
                     <p></p>
                  </div>
                </div>
              </div>    

            <div className="col-md-4 mb-2">
                <div className="card">
                  <br />
                  <div className="text-center">
                  <div className="Date-label">
                      <p>July 5, 2023</p>
                    </div>
                  </div>
                  <div className="text-center">
                    <h3 className="card-title">
                    Subscribe to IRS on social media and e-news to stay up to date
                    </h3>
                  </div>
                  <div className="p-3">
                  <ExpandableText descriptionLength={1}>
                  It’s easy to stay up to date on tax information year-round with the IRS verified social media accounts and e-news services. Taxpayers can get tips, guidance and the latest tax law news delivered to their social feed or inbox.
                    
                   
                  <h1>IRS Social Media Platforms</h1>

                  <h2>Twitter</h2>
                  <p>Information for taxpayers, businesses, tax professionals, and job seekers. The IRS shares updates in English and Spanish.</p>

                  <h2>Facebook</h2>
                  <p>Tax information for a general audience in English and Spanish.</p>

                  <h2>Instagram</h2>
                  <p>Taxpayer-friendly information on a variety of topics.</p>

                  <h2>YouTube</h2>
                  <p>Short videos on specific tax topics for tax professionals and small businesses. The multilingual channel features videos in Spanish, Chinese, Haitian-Creole, Korean, Russian and Vietnamese.</p>

                  <h2>LinkedIn</h2>
                  <p>Key agency communications and job opportunities.</p>

                  <h2>IRS2Go mobile app</h2>
                  <p>The agency also has a free mobile app in English and Spanish: IRS2Go. Taxpayers can check their refund status, find free tax help, watch IRS YouTube videos and get daily tax tips. The IRS2Go app is available from the Google Play Store, Apple App Store, and Amazon Appstore.</p>

                  <p><strong>The IRS never personally contacts a taxpayer on social media to ask for personal or financial information. Taxpayers should be aware that scammers may pose as the IRS to steal a taxpayer’s identity or defraud them.</strong></p>

                  <h2>Sign up for automatic email updates</h2>
                  <p>The IRS e-News subscription service sends tax information by email for many different audiences, including:</p>

                  <ul>
                      <li>IRS Outreach Connection − Up-to-date materials for tax professionals and partner groups inside and outside the tax community. Subscribers can easily share the material with their clients or members through email, social media, and the web.</li>
                      <li>IRS Tax Tips– Daily tips in plain language on a wide range of general interest tax topics for taxpayers.</li>
                      <li>IRS Newswire − News releases on a wide range of tax issues from breaking news to details on legal guidance.</li>
                      <li>IRS News in Spanish - Noticias del IRS en Español− IRS news releases, tax tips, and updates in Spanish.</li>
                      <li>e-News for Tax Professionals– A weekly roundup of news releases and legal guidance for tax professionals.</li>
                      <li>e-News for Small Businesses – Tax information for small business owners and self-employed individuals.</li>
                  </ul>
                    
                  </ExpandableText>
                  </div>
                </div>
              </div>

               {/* CARD CONTENT 1 */}
               <div className="col-md-4 mb-2">
                <div className="card">
                  <br />
                  <div className="text-center">
                    <div className="Date-label">
                      <p>Jan 20, 2023</p>
                    </div>
                  </div>
                  <div className="text-center">
                    <h3 className="card-title">
                    Issue Number:  W-2s, W-3s, 1099-NEC, Information Returns Due Date: January 31
                    </h3>
                  </div>
                  <div className="p-3">
                    <p>Here is a video tax tip from the IRS:</p>
                    <p>W-2s, W-3s, 1099-NEC, Information Returns Due Date: January 31</p>
                    <p>Subscribe today: The IRS YouTube channels provide short, informative videos on various tax related topics in English, Spanish and ASL.</p>
                    <a>&#x2022;</a>
                    <a href="https://lnks.gd/l/eyJhbGciOiJIUzI1NiJ9.eyJidWxsZXRpbl9saW5rX2lkIjoxMjksInVyaSI6ImJwMjpjbGljayIsImJ1bGxldGluX2lkIjoiMjAyMzAxMjAuNzAxMjM4NTEiLCJ1cmwiOiJodHRwOi8vd3d3LnlvdXR1YmUuY29tL0BpcnN2aWRlb3MifQ.mZHYYBc6XrbUPXJqsna4B4LbQQr5-50QrT_WnhT3Fbw/s/14124249/br/153133956256-l"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      IRS Videos</a><p></p>
                      <a>&#x2022;</a>
                      <a href="https://lnks.gd/l/eyJhbGciOiJIUzI1NiJ9.eyJidWxsZXRpbl9saW5rX2lkIjoxMzAsInVyaSI6ImJwMjpjbGljayIsImJ1bGxldGluX2lkIjoiMjAyMzAxMjAuNzAxMjM4NTEiLCJ1cmwiOiJodHRwOi8vd3d3LnlvdXR1YmUuY29tL0BpcnNtdWx0aWxpbmd1YWwifQ.aLB22Z3-ilIQxhsWPqLz2Di7JHv0AuXBtdLxKw7S-XM/s/14124249/br/153133956256-l"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      IRS MultiLingual Videos</a><p></p>
                      <a>&#x2022;</a>
                      <a href="https://lnks.gd/l/eyJhbGciOiJIUzI1NiJ9.eyJidWxsZXRpbl9saW5rX2lkIjoxMzEsInVyaSI6ImJwMjpjbGljayIsImJ1bGxldGluX2lkIjoiMjAyMzAxMjAuNzAxMjM4NTEiLCJ1cmwiOiJodHRwOi8vd3d3LnlvdXR1YmUuY29tL0BpcnN2aWRlb3NBU0wifQ.FUoHNx6IenNzQNdsZ0IEYwGNKs-830yIaTwZKer8b0c/s/14124249/br/153133956256-l"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      IRS Videos ASL</a>
                  </div>
                </div>
              </div>      
             
          
              
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default News;
