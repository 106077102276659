import Card from "./Card";
function Services() {
  return (
      <>
      <div
        style={{ marginTop: "0rem", width: "100%", height: "0vw", paddingBottom: "5vw" }}
        className="service-scroll"
      ></div>
    <div className="container services">
        <h2 className="main-title text-center">SERVICES</h2>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <Card title="Individual" img="card1.png" text="1040 Tax Preperations" />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Business" img="card2.png" text="Schedule C Small Business Preperations" />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Planning" img="card3.png" text="Personal tax planning strategies" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}
export default Services;
