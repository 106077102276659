function About() {
  return (
    <>
      <div
        style={{ marginTop: "5rem", width: "100%", height: "5vw", paddingBottom: "2vw" }}
        className="about-scroll"
      ></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center">
            <img alt="about" src="../img/peems.jpeg" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">ABOUT</h2>
            <p className="main-p">
              Jennifer is an experienced Certified Public Accountant with a
              demonstrated history of working with small business and
              individuals. She is skilled in Tax Preparation and has had a
              successful accounting business for over 20 years. Jennifer is a
              strong accounting professional with a Master’s Degree focused in
              TAX ACCOUNTING from the University of Florida, Fisher School of
              Accounting.
            </p>
            <p className="main-p">
                Proud member of TBWCPA & the National Society of Tax Professionals
            </p>
            <img alt="about" src="../img/tampabaywomen.jpeg" className="tbwomen-img" />
            <p className="main-p">
              Florida License # AC 35156
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
