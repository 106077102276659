function Header() {
  return (
    <header>
      <video src="/movingpapers.mp4" loop autoPlay muted></video>
      <h1><img className="MainLogo" src="/img/MainLogo.png" alt="logo"></img></h1>
      <div className="intro">
       <p>Established in 2002, Cutting Taxes LLC provides accounting services 
        to the Tampa Bay Area with over two decades of experience.</p>
      </div>

      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
